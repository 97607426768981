<template>
  <div class="content">
    <mHeader></mHeader>
    <div class="title">Brand History</div>
    <img style="width: 100%;" :src="picUrlaaa + history.picUrl" />
    <div
        style="width: 100%;overflow: auto;margin-top:1rem;text-align: justify;padding:1rem 1rem"
        v-html="escapeHtml(history.detail)"
      ></div>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mHeader from "../components/mHeader";
import mFooter from "../components/mFooter";
export default {
  data() {
    return {
      history: null,
      picUrlaaa: "http://47.241.73.160:80/shop/images",
    };
  },
  components: {
    mHeader,
    mFooter,
  },
  created() {
    this.history = JSON.parse(localStorage.getItem("history"));
  },
  methods: {
    escapeHtml(html) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return html.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
        return arrEntities[t];
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  padding-top: 4rem;
}
html,
body {
  overflow: auto;
  height: 100%;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5f1985;
}
p {
  text-align: justify;
  line-height: 1.5rem;
  font-size: 1rem;
  overflow: auto;
  padding: 1rem;
}
</style>
