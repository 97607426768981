<template>
  <div class="content">
    <mHeader></mHeader>
    <div class="title">Stories</div>
    <div v-for="(news,i) in newsList" :key="i" @click="goNews(i)">
      <img
        :src="picUrlaaa + news.picUrl"
        style="width:100%;object-fit: cover;"
      />
      <div class="title2">{{ news.title }}</div>
      <div class="more">Read more ></div>
    </div>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mHeader from "../components/mHeader";
import mFooter from "../components/mFooter";
export default {
  data() {
    return {
      newsList: null,
      picUrlaaa: "http://47.241.73.160:80/shop/images",
    };
  },
  components: {
    mHeader,
    mFooter,
  },
  created() {
    this.newsList = JSON.parse(localStorage.getItem("newsList"));
  },
  methods: {
    goNews(e) {
      localStorage.setItem("newsNo", e);
      this.$router.push("/mStories");
    },
  },
};
</script>

<style scoped lang="less">
html, body {
    overflow: auto;
    height: 100%;
}
.content{
  padding-top: 4rem;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5f1985;
  margin-bottom: 1rem;
}
.title2 {
  width: 100%;
  text-align: center;
  font-size: 1rem;
}
.more {
  width: 100%;
  text-align: center;
  color: #5f1985;
  font-size: 1rem;
  margin-bottom: 2rem;
}
</style>
