<template>
  <div class="main">
    <div class="header">
      <img
        @click="goPage(1)"
        style="float: left; height: 3rem"
        src="../assets/logo.png"
      />
      <span style="line-height: 3rem;margin-left: 1rem;width: 10px;"><a href="http://www.pololubricants.cn">中文</a></span>
      <div @click="openMenu">
        <img class="menuIcon" src="../assets/menu.png" />
        <img class="arrow" v-if="!arrow" src="../assets/down.png" />
        <img class="arrow" v-if="arrow" src="../assets/up.png" />
      </div>
    </div>
    <transition name="change">
      <div v-if="arrow" class="menu">
        <div @click="goPage(1)" class="menu-list">Home</div>
        <div @click="goPage(2)" class="menu-list">The Brand</div>
        <div @click="goPage(3)" class="menu-list">Products</div>
        <div @click="goPage(4)" class="menu-list">Stories</div>
        <div @click="goPage(5)" class="menu-list">Videos</div>
        <div @click="goPage(6)" class="menu-list">Contact</div>
        <div class="Footer2">-- Performance You Can Trust --</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      arrow: false,
    };
  },
  methods: {
    openMenu() {
      this.arrow = !this.arrow;
    },
    goPage(e) {
      switch (e) {
        case 1:
          this.$router.push("/mHome");
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          this.arrow = false;
          break;
        case 2:
          this.$router.push("/mHistory");
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          this.arrow = false;
          break;
        case 3:
          this.$router.push("/mProducts");
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          this.arrow = false;
          break;
        case 4:
          this.$router.push("/mStoriesList");
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          this.arrow = false;
          break;
        case 5:
          this.$router.push("/mVideo");
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          this.arrow = false;
          break;
        case 6:
          this.$router.push("/mHome");
          setTimeout(() => {
            document.querySelector("#contact").scrollIntoView();
          }, 1);
          this.arrow = false;
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
.main {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: #fff;
}
.header {
  height: 4rem;
  padding: 0.5rem;
  box-sizing: border-box;
  position: relative;
}
.menuIcon {
  float: right;
  height: 1.5rem;
  right: 2rem;
  top: 1.5rem;
  position: absolute;
}
.arrow {
  float: right;
  height: 0.75rem;
  right: 1.4rem;
  top: 1.8rem;
  position: absolute;
}
.menu {
  float: left;
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 999999;
  padding: 0 1rem;
  padding-bottom: 1rem;
  height: 100rem;
}
.menu-list {
  border-bottom: 1px solid #ddd;
  padding: 1rem 0;
}
.change-enter-active {
  transition: all 0.5s ease;
}
.change-enter {
  opacity: 0;
}
.change-leave-active {
  transition: all 0.5s ease;
}
.change-leave-to {
  opacity: 0;
}
.Footer2 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #5f1985;
  color: #fff;
  text-align: center;
  height: 2rem;
  line-height: 2rem;
}
a:link {
  color: #999;
}
a:visited {
  color: #999;
}
</style>
