<template>
  <Layout>
    <Header
      style="
        height: 60px;
        background-color: #ffffff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
      "
      ><img style="float: left; height: 58px" src="../assets/logo.png" />
      <span style="line-height: 60px;width: 36px;float: right"
        ><a href="http://www.pololubricants.cn">中文</a></span
      >
      <Menu
        style="float: right"
        mode="horizontal"
        :active-name="activeName"
        @on-select="topage"
      >
        <MenuItem name="1">Home</MenuItem>
        <MenuItem name="2">Brand History</MenuItem>
        <MenuItem name="3">Products</MenuItem>
        <MenuItem name="4">Stories</MenuItem>
        <MenuItem name="5">Videos</MenuItem>
        <MenuItem name="6">Contact</MenuItem>
      </Menu>
    </Header>
    <Content style="">
      <!-------------------------------------------------- banner ----------------------------------------------->
      <div id="id1" class="top" style="background-color: #fff">
        <Carousel
          :autoplay="autoplay"
          :autoplay-speed="5000"
          loop
          style="width: 100%; height: 70vh"
          v-if="Carousel"
          @mouseover="stopAutoPlay"
        >
          <CarouselItem v-if="banner != 'null'">
            <img
              @mouseenter="stopAutoPlay"
              @mouseover="stopAutoPlay"
              @mouseleave="beginAutoPlay"
              @mouseout="beginAutoPlay"
              :src="banner"
              style="object-fit: cover; width: 100%; height: 70vh"
            />
          </CarouselItem>
          <CarouselItem v-if="banner2 != 'null'">
            <img
              @mouseenter="stopAutoPlay"
              @mouseover="stopAutoPlay"
              @mouseleave="beginAutoPlay"
              @mouseout="beginAutoPlay"
              :src="banner2"
              style="object-fit: cover; width: 100%; height: 70vh"
            />
          </CarouselItem>
          <CarouselItem v-if="banner3 != 'null'">
            <img
              @mouseenter="stopAutoPlay"
              @mouseover="stopAutoPlay"
              @mouseleave="beginAutoPlay"
              @mouseout="beginAutoPlay"
              :src="banner3"
              style="object-fit: cover; width: 100%; height: 70vh"
            />
          </CarouselItem>
          <CarouselItem v-if="banner4 != 'null'">
            <img
              @mouseenter="stopAutoPlay"
              @mouseover="stopAutoPlay"
              @mouseleave="beginAutoPlay"
              @mouseout="beginAutoPlay"
              :src="banner4"
              style="object-fit: cover; width: 100%; height: 70vh"
            />
          </CarouselItem>
          <CarouselItem v-if="banner5 != 'null'">
            <img
              @mouseenter="stopAutoPlay"
              @mouseover="stopAutoPlay"
              @mouseleave="beginAutoPlay"
              @mouseout="beginAutoPlay"
              :src="banner5"
              style="object-fit: cover; width: 100%; height: 70vh"
            />
          </CarouselItem>
          <CarouselItem v-if="banner6 != 'null'">
            <img
              @mouseenter="stopAutoPlay"
              @mouseover="stopAutoPlay"
              @mouseleave="beginAutoPlay"
              @mouseout="beginAutoPlay"
              :src="banner6"
              style="object-fit: cover; width: 100%; height: 70vh"
            />
          </CarouselItem>
        </Carousel>
      </div>
      <!-------------------------------------------------- history ----------------------------------------------->
      <div class="content" id="id2" style="height: 100vh">
        <Row type="flex" justify="center" align="middle" style="height: 100vh">
          <Col span="12">
            <h2 style="color: #5f1985; font-size: 60px">AOCUSA-POLO</h2>
            <div style="margin: 30px 0">
              <span style="font-size: 24px"
                >Founded in
                <span
                  style="
                    color: #5f1985;
                    font-size: 48px;
                    padding-right: 10px;
                    margin-right: 10px;
                    border-right: 1px solid #000;
                  "
                  >1903</span
                >
                <span style="color: #5f1985; font-size: 48px">10</span>
                Factories
                <span
                  style="
                    color: #5f1985;
                    font-size: 48px;
                    padding-left: 10px;
                    margin-left: 10px;
                    border-left: 1px solid #000;
                  "
                  >6</span
                >
                Independent Storage Centers
                <span
                  style="
                    color: #5f1985;
                    font-size: 48px;
                    padding-left: 10px;
                    margin-left: 10px;
                    border-left: 1px solid #000;
                  "
                  >100</span
                >
                Million Gallons Of Total Capacity</span
              >
            </div>
            <div
              class="historyInfo"
              v-html="escapeHtml(historyInfo.detail)"
            ></div>
            <a @click="history = true">MORE >>></a>
          </Col>
          <Col span="4"
            ><img
              style="width: 300px; margin-left: 32px"
              :src="picUrlaaa + historyInfo.picUrl"
          /></Col>
        </Row>
      </div>
      <!-------------------------------------------------- product ----------------------------------------------->
      <div
        id="id3"
        class="content"
        style="
          display: flex;
          background-color: #fff;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          style="
            width: 1200px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
          "
        >
          <div class="cate" @click="goProduct(1)">
            <div></div>
            <img style="width: 300px" src="../assets/passenger car.jpg" />
            <div class="catename">Passenger Cars</div>
          </div>
          <div class="cate" @click="goProduct(2)">
            <img style="width: 300px" src="../assets/motorcycle.jpg" />
            <div class="catename">Motorcycles</div>
          </div>
          <div class="cate" @click="goProduct(3)">
            <img style="width: 300px" src="../assets/pickup.jpg" />
            <div class="catename">Light Duty Diesel & Pickup Trucks</div>
          </div>
          <div class="cate" @click="goProduct(4)">
            <img style="width: 300px" src="../assets/heavy duty.jpg" />
            <div class="catename">Heavy Duty Trucks & Buses</div>
          </div>
          <div class="cate" @click="goProduct(5)">
            <img style="width: 300px" src="../assets/transmission.jpg" />
            <div class="catename">Transmission Fluids</div>
          </div>
          <div class="cate" @click="goProduct(6)">
            <img style="width: 300px" src="../assets/industrial.jpg" />
            <div class="catename">Industrial Oils</div>
          </div>
        </div>
      </div>
      <!-------------------------------------------------- story ----------------------------------------------->
      <div
        id="id4"
        class="content"
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          style="
            margin-right: 30px;
            display: flex;
            flex-flex-direction: row;
            align-items: center;
            justify-content: center;
            max-width: 900px;
          "
        >
          <div
            id="newsInfo"
            style="
              max-height: 600px;
              overflow: auto;
              width:1200px
              min-width: 600px;
              position: relative;
            "
          >
            <img
              :src="picUrlaaa + news.picUrl"
              style="
                height: 150px;
                width: 200px;
                object-fit: cover;
                margin-right: 30px;
                float: right;
                position: absolute;
                top: 50px;
                right: 0;
              "
            />
            <div
              style="
                color: #5f1985;
                font-weight: bold;
                font-size: 32px;
                margin-bottom: 18px;
              "
            >
              {{ news.title }}
            </div>
            <div class="newsInfo" v-html="escapeHtml(news.detail)"></div>
            <!-- <div
              style="color: #5f1985;font-size:16px;margin-top:36px;cursor: pointer"
              @click="openNews()"
            >
              READ MORE >>>
            </div> -->
          </div>
        </div>
        <List
          style="
            width: 300px;
            min-width: 300px;
            overflow: auto;
            max-height: 600px;
          "
          border
        >
          <div slot="header" style="color: #5f1985; font-weight: bold">
            Stories
          </div>
          <ListItem v-for="list in newsList" :key="list.id">
            <img
              style="
                width: 80px;
                height: 60px;
                object-fit: cover;
                margin-right: 20px;
              "
              :src="picUrlaaa + list.picUrl"
              @click="getNews(list)"
            />
            <div @click="getNews(list)" style="cursor: pointer">
              {{ list.title }} >
            </div>
          </ListItem>
        </List>
      </div>
      <!-------------------------------------------------- video ----------------------------------------------->
      <div
        id="id5"
        class="content"
        style="
          background-color: #fff;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        "
      >
        <div>
          <div
            style="
              color: #5f1985;
              font-weight: bold;
              font-size: 32px;
              margin-bottom: 18px;
            "
          >
            {{ videoTitle }}
          </div>
          <video
            style="max-width: 900px; max-height: 600px; margin-right: 30px"
            :src="picUrlaaa + video"
            controls="controls"
          ></video>
          <!-- <div
            style="
              line-height: 32px;
              font-size: 18px;
              overflow-x: hidden;
              max-width: 900px;
              max-height: 300px;
              overflow: auto;
            "
          >
            {{ videoDetail }}
          </div> -->
          <div
            @click="videoModal = true"
            style="
              cursor: pointer;
              width: 900px;
              font-size: 20px;
              text-align: center;
            "
          >
            video info >
          </div>
        </div>
        <List
          style="
            width: 300px;
            min-width: 300px;
            overflow: auto;
            max-height: 600px;
          "
          border
        >
          <div slot="header" style="color: #5f1985; font-weight: bold">
            Videos
          </div>
          <ListItem v-for="list in videoList" :key="list.id">
            <video
              style="width: 120px; margin-right: 10px; cursor: pointer"
              @click="getVideo(list)"
              :src="picUrlaaa + list.video"
            />
            <div @click="getVideo(list)" style="cursor: pointer">
              {{ list.title }} >
            </div>
          </ListItem>
        </List>
      </div>
      <!-------------------------------------------------- contact ----------------------------------------------->
      <div
        id="id6"
        class="content"
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        "
      >
        <div style="width: 400px; margin-right: 10px; text-align: center">
          <p
            style="
              color: #5f1985;
              font-size: 24px;
              font-weight: bold;
              text-align: center;
            "
          >
            Contact Us
          </p>
          <p style="font-size: 16px; text-align: center">
            www.pololubricants.asia<br />enquiry@pololubricants.asia
          </p>
          <p
            style="
              color: #5f1985;
              font-size: 24px;
              font-weight: bold;
              text-align: center;
              margin-top: 20px;
            "
          >
            United States Office
          </p>
          <p style="font-size: 16px; text-align: center">
            1061 West 5th St. Azusa, CA 91702 USA.
          </p>
          <a
            href="https://www.google.de/maps/place/1061+W+5th+St,+Azusa,+CA+91702,+USA/@34.1303905,-117.9247547,17z/data=!3m1!4b1!4m5!3m4!1s0x80c3278a4f08a087:0xfa74948b3757e2e6!8m2!3d34.1303905!4d-117.922566?hl=en"
            target="view_window"
            style="font-size: 16px"
            >location >></a
          >
          <p
            style="
              color: #5f1985;
              font-size: 24px;
              font-weight: bold;
              text-align: center;
              margin-top: 20px;
            "
          >
            Singapore Office
          </p>
          <p style="font-size: 16px; text-align: center">
            51 Goldhill Plaza #23-11 Singapore (308900)
          </p>
          <a
            href="https://www.google.com.hk/maps/place/51+Goldhill+Plaza,+23%E6%96%B0%E5%8A%A0%E5%9D%A1+308900/@1.3180052,103.8423348,17.81z/data=!4m5!3m4!1s0x31da19e7c7aa13d5:0x61d60ebe5b77b778!8m2!3d1.3183037!4d103.8429438?hl=en"
            target="view_window"
            style="font-size: 16px"
            >location >></a
          >
          <p
            style="
              color: #5f1985;
              font-size: 24px;
              font-weight: bold;
              text-align: center;
              margin-top: 20px;
            "
          >
            China Office
          </p>
          <p style="font-size: 16px; text-align: center">
            56 Zhenshi Road, Wuxi, Jiangsu, China
          </p>
          <a
            href="https://www.google.de/maps/place/Zhen+Shi+Lu,+Huishan+Qu,+Wuxi+Shi,+Jiangsu+Sheng,+China/@31.6478305,120.2627085,17.54z/data=!4m5!3m4!1s0x35b38c6e4eb74815:0xddb824e9120f4631!8m2!3d31.6493026!4d120.2640486?hl=en"
            target="view_window"
            style="font-size: 16px"
            >location >></a
          >
        </div>
        <div
          style="
            width: 820px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              width: 820px;
              text-align: center;
              font-size: 24px;
              margin-bottom: 20px;
              color: #5f1985;
            "
          >
            Drop us a message
          </div>
          <div>
            <Form ref="form" :model="form" :rules="rule">
              <div style="width: 400px; float: left; margin-right: 20px">
                <FormItem prop="name">
                  <Input v-model="form.name" placeholder="Name*" />
                </FormItem>
                <FormItem prop="email">
                  <Input v-model="form.email" placeholder="Email Address*" />
                </FormItem>
                <FormItem prop="title">
                  <Input v-model="form.title" placeholder="Subject*" />
                </FormItem>
                <FormItem prop="choice">
                  <Select v-model="form.choice" placeholder="General Enquiries">
                    <Option value="Partnership Opportunities"
                      >Partnership Opportunities</Option
                    >
                    <Option value="Sales Enquiry">Sales Enquiry</Option>
                    <Option value="Product Enquiry">Product Enquiry</Option>
                    <Option value="Articles Contribution"
                      >Articles Contribution</Option
                    >
                    <Option value="Feedback">Feedback</Option>
                  </Select>
                </FormItem>
              </div>
              <div style="width: 400px; float: left">
                <FormItem prop="text">
                  <Input
                    style="margin-bottom: 10px"
                    v-model="form.text"
                    type="textarea"
                    :autosize="{ minRows: 5, maxRows: 5 }"
                    placeholder="Type your message here"
                    show-word-limit
                    maxlength="255"
                  />
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="send()">Send</Button>
                </FormItem>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal v-model="history" width="1200" :closable="false">
        <div class="historyInfo2" v-html="escapeHtml(historyInfo.detail)"></div>
        <div slot="footer">
          <Button @click="history = false">ok</Button>
        </div>
      </Modal>
      <!-- <Modal v-model="newsModel" width="1200" :closable="false">
        <div v-html="escapeHtml(news.detail)"></div>
        <div slot="footer">
          <Button @click="newsModel = false">ok</Button>
        </div>
      </Modal> -->
      <Modal v-model="videoModal" width="1000" :closable="false">
        <div style="text-align: center">
          <div
            style="
              color: #5f1985;
              font-weight: bold;
              font-size: 32px;
              margin-bottom: 18px;
              text-align: center;
            "
          >
            {{ videoTitle }}
          </div>
          <video
            style="max-width: 900px; max-height: 600px"
            :src="picUrlaaa + video"
            controls="controls"
          ></video>
          <div style="line-height: 32px; font-size: 18px; max-width: 900px">
            {{ videoDetail }}
          </div>
        </div>
        <div slot="footer">
          <Button @click="videoModal = false">ok</Button>
        </div>
      </Modal>
    </Content>
    <Footer class="Footer">-- Performance You Can Trust --</Footer>
  </Layout>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        title: "",
        choice: "",
        text: "",
      },
      autoplay: true,
      Carousel: false,
      history: false,
      historyInfo: {
        picUrl: "",
      },
      modal1: false,
      newsModel: false,
      videoModal: false,
      picUrlaaa: "http://47.241.73.160:80/shop/images",
      banner: "null",
      banner2: "null",
      banner3: "null",
      banner4: "null",
      banner5: "null",
      banner6: "null",
      video: "",
      videoTitle: "",
      videoDetail: "",
      video: "",
      videoList: "",
      activeName: "1",
      productList: [],
      allproductList: [],
      info: { detail: "" },
      news: {
        detail: "",
      },
      newsList: [{ newsList: [] }],
      value1: 0,
      rule: {
        name: [
          {
            required: true,
            message: "Please fill in the name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please fill in the email",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "Please fill in the subject",
            trigger: "blur",
          },
        ],
        choice: [
          {
            required: true,
            message: "Please select the general enquiries",
            trigger: "change",
          },
        ],
        text: [
          {
            required: true,
            message: "Please type your message",
            trigger: "blur",
          },
        ],
      },
    };
  },
  beforeMount() {},
  beforeCreate() {},
  mounted() {
    this.getInfo();
    let that = this;
    setTimeout(() => {
      if (that.$route.params.id != undefined) {
        that.topage(that.$route.params.id);
      } else {
        that.topage(1);
      }
    }, 1000);
    window.addEventListener("scroll", this.showIcon);
  },
  created() {},
  methods: {
    async getInfo() {
      const { data: res } = await this.$http.get("/polo/index/index");
      if (res.banner[0].bannerPic != "null") {
        this.banner = this.picUrlaaa + res.banner[0].bannerPic;
      }
      if (res.banner[1].bannerPic != "null") {
        this.banner2 = this.picUrlaaa + res.banner[1].bannerPic;
      }
      if (res.banner[2].bannerPic != "null") {
        this.banner3 = this.picUrlaaa + res.banner[2].bannerPic;
      }
      if (res.banner[3].bannerPic != "null") {
        this.banner4 = this.picUrlaaa + res.banner[3].bannerPic;
      }
      if (res.banner[4].bannerPic != "null") {
        this.banner5 = this.picUrlaaa + res.banner[4].bannerPic;
      }
      if (res.banner[5].bannerPic != "null") {
        this.banner6 = this.picUrlaaa + res.banner[5].bannerPic;
      }
      this.video = res.video[0].video;
      this.videoTitle = res.video[0].title;
      this.videoDetail = res.video[0].detail;
      this.Carousel = true;
      this.videoList = res.video;
      this.productList = res.productList;
      this.historyInfo = res.newsList[0].newsList[0];
      this.newsList = res.newsList[1].newsList;
      let that = this;
      setTimeout(() => {
        that.getNews(that.newsList[0]);
      }, 100);
      res.productList.forEach((item) => {
        item.productList.forEach((a) => {
          this.allproductList.push(a);
        });
      });
    },
    topage(e) {
      document.querySelector("#id" + e).scrollIntoView();
      if (e == "4") {
        this.getNews(this.newsList[0]);
      }
      if (e == "5") {
        this.video = this.videoList[0].video;
      }
    },
    getVideo(e) {
      this.video = e.video;
      this.videoTitle = e.title;
      this.videoDetail = e.detail;
    },
    escapeHtml(html) {
      // setTimeout(() => {
      //   var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      //   return html.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
      //     return arrEntities[t];
      //   });
      // }, 5000);
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return html.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
        return arrEntities[t];
      });
    },
    getNews(e) {
      this.news = e;
      document.querySelector("#newsInfo").scrollTop = 0;
    },
    goProduct(e) {
      this.$router.push({
        name: "Product",
        params: {
          id: e,
        },
      });
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    send() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "/polo/index/sendMail",
            this.form
          );
          if (res.code == 0) {
            this.form = this.$options.data().form;
            return this.$Message.success("Success");
          } else {
            return this.$Message.error("Fail");
          }
        } else {
          this.$Message.error("Fail!");
        }
      });
      // const { data: res } = await this.$http.post(
      //   "/polo/index/sendMail",
      //   this.form
      // );
    },
    showIcon() {
      if (document.documentElement.scrollTop <= 0.35 * window.innerHeight) {
        this.activeName = "1";
      } else if (
        1.2 * window.innerHeight >= document.documentElement.scrollTop &&
        document.documentElement.scrollTop > 0.35 * window.innerHeight
      ) {
        this.activeName = "2";
      } else if (
        2.2 * window.innerHeight >= document.documentElement.scrollTop &&
        document.documentElement.scrollTop > 1.2 * window.innerHeight
      ) {
        this.activeName = "3";
      } else if (
        3.2 * window.innerHeight >= document.documentElement.scrollTop &&
        document.documentElement.scrollTop > 2.2 * window.innerHeight
      ) {
        this.activeName = "4";
      } else if (
        4.2 * window.innerHeight >= document.documentElement.scrollTop &&
        document.documentElement.scrollTop > 3.2 * window.innerHeight
      ) {
        this.activeName = "5";
      } else if (
        4.2 * window.innerHeight <
        document.documentElement.scrollTop
      ) {
        this.activeName = "6";
      }
    },
    openNews() {
      this.newsModel = true;
    },
    stopAutoPlay() {
      this.autoplay = false;
    },
    beginAutoPlay() {
      this.autoplay = true;
    },
  },
};
</script>

<style scoped lang="less">
.top {
  width: 100%;
  height: calc(70vh + 60px);
  box-sizing: border-box;
  padding-top: 60px;
}
.content {
  width: 100%;
  height: 100vh;
}
.Footer {
  width: 100%;
  background-color: #5f1985;
  color: #fff;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.historyInfo {
  text-align: justify;
  line-height: 32px;
  font-size: 16px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
.cate {
  width: 300px;
  margin: 10px 50px;
  cursor: pointer;
}
.cate img {
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.cate:hover img {
  transform: scale(1.1);
}
.cate:hover .catename {
  color: #5f1985;
  font-weight: bold;
}
.catename {
  font-size: 18px;
  margin-top: 12px;
  text-align: center;
}
.newsInfo {
  line-height: 32px;
  font-size: 18px;
  overflow-x: hidden;
}
/deep/.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu-active,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item:hover,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu:hover {
  background-color: #5f1985;
  color: #fff;
  border-radius: 64px;
}
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu {
  height: 30px;
  line-height: 30px;
  margin-top: 15px;
  border: none;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  background-clip: padding-box;
  min-height: 28px;
}
::-webkit-scrollbar-track-piece {
  background-color: #fff;
}
a:link {
  color: #999;
}
a:visited {
  color: #999;
}
</style>
