<template>
  <div class="content">
    <mHeader></mHeader>
    <div class="title">Stories</div>
    <img :src="picUrlaaa + news.picUrl" style="width:100%;object-fit: cover;" />
    <div class="title2">{{ news.title }}</div>
    <div
      style="width: 100%;overflow: auto;margin-top:1rem;text-align: justify;padding:0 1rem"
      v-html="escapeHtml(news.detail)"
    ></div>
    <div class="btnList">
      <Button type="primary" :disabled="last" @click="lastPage()"
        >< previous story</Button
      >
      <Button type="primary" :disabled="next" @click="nextPage()"
        >next story ></Button
      >
    </div>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mHeader from "../components/mHeader";
import mFooter from "../components/mFooter";
export default {
  data() {
    return {
      newsList: [],
      last: false,
      next: false,
      newsNo: null,
      news: null,
      picUrlaaa: "http://47.241.73.160:80/shop/images",
    };
  },
  components: {
    mHeader,
    mFooter,
  },
  created() {
    this.newsNo = Number(localStorage.getItem("newsNo"));
    this.newsList = JSON.parse(localStorage.getItem("newsList"));
    this.changeNews(this.newsNo);
    this.changeBtn();
  },
  methods: {
    escapeHtml(html) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return html.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
        return arrEntities[t];
      });
    },
    changeBtn() {
      if (this.newsNo == "0") {
        this.last = true;
      } else if (this.newsNo == this.newsList.length - 1) {
        this.next = true;
      } else {
        this.last = false;
        this.next = false;
      }
    },
    changeNews(e) {
      this.news = this.newsList[e];
    },
    lastPage() {
      this.newsNo = this.newsNo - 1;
      this.changeNews(this.newsNo);
      this.changeBtn();
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    nextPage() {
      this.newsNo = this.newsNo + 1;
      this.changeNews(this.newsNo);
      this.changeBtn();
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
  },
};
</script>

<style scoped lang="less">
.content {
  padding-top: 4rem;
}
html,
body {
  overflow: auto;
  height: 100%;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5f1985;
  margin-bottom: 1rem;
}
.title2 {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  color: #5f1985;
}
.btnList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 4rem 1rem;
}
</style>
