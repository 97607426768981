<template>
  <div class="content">
    <mHeader></mHeader>
    <!-- banner -->
    <div>
      <Carousel
        autoplay
        :autoplay-speed="5000"
        loop
        style="width: 100%; height: 15rem"
      >
        <CarouselItem v-if="banner != 'null'">
          <img :src="banner" class="banner" />
        </CarouselItem>
        <CarouselItem v-if="banner2 != 'null'">
          <img :src="banner2" class="banner" />
        </CarouselItem>
        <CarouselItem v-if="banner3 != 'null'">
          <img :src="banner3" class="banner" />
        </CarouselItem>
        <CarouselItem v-if="banner4 != 'null'">
          <img :src="banner4" class="banner" />
        </CarouselItem>
        <CarouselItem v-if="banner5 != 'null'">
          <img :src="banner5" class="banner" />
        </CarouselItem>
        <CarouselItem v-if="banner6 != 'null'">
          <img :src="banner6" class="banner" />
        </CarouselItem>
      </Carousel>
    </div>
    <!-- history -->
    <div @click="goPage(2)">
      <div class="title">Brand History</div>
      <div class="title2">
        POLO founded in 1903, is an independent lubricant brand owned by AOCUSA
        Group.
      </div>
      <img style="width: 100%" :src="picUrlaaa + historyPic" />
      <div class="more">Learn more ></div>
    </div>
    <!-- products -->
    <div @click="goPage(3)">
      <div class="title">Products</div>
      <div class="title2">See our range of lubricants for your needs</div>
      <img style="width: 100%" src="../assets/mProduct.png" />
      <div class="more">Learn more ></div>
    </div>
    <!-- Stories -->
    <div @click="goPage(4)">
      <div class="title">Stories</div>
      <div class="title2">Check out our latest stories</div>
      <img style="width: 100%" :src="picUrlaaa + storyPic" />
      <div class="title2">How to select the correct motorcycle oils?</div>
      <div class="more">Learn more ></div>
    </div>
    <!-- Videos -->
    <div>
      <div class="title">Videos</div>
      <div class="title2">Check out our latest videos</div>
      <video
        style="width: 100%"
        :src="picUrlaaa + video"
        controls="controls"
      ></video>
      <div id="contact"></div>
      <div class="more" @click="goPage(5)">Learn more ></div>
    </div>
    <!-- Contact -->
    <div>
      <div class="title">Contact</div>
      <div class="title3">Contact Us</div>
      <div class="title2">
        www.pololubricants.asia<br />enquiry@pololubricants.asia
      </div>
      <div class="title3">United States Office</div>
      <div class="title2">1061 West 5th St. Azusa, CA 91702 USA.</div>
      <a
        href="https://www.google.de/maps/place/1061+W+5th+St,+Azusa,+CA+91702,+USA/@34.1303905,-117.9247547,17z/data=!3m1!4b1!4m5!3m4!1s0x80c3278a4f08a087:0xfa74948b3757e2e6!8m2!3d34.1303905!4d-117.922566?hl=en"
        target="view_window"
        class="link"
        >location >></a
      >
      <div class="title3">Singapore Office</div>
      <div class="title2">51 Goldhill Plaza #23-11 Singapore (308900)</div>
      <a
        href="https://www.google.com.hk/maps/place/51+Goldhill+Plaza,+23%E6%96%B0%E5%8A%A0%E5%9D%A1+308900/@1.3180052,103.8423348,17.81z/data=!4m5!3m4!1s0x31da19e7c7aa13d5:0x61d60ebe5b77b778!8m2!3d1.3183037!4d103.8429438?hl=en"
        target="view_window"
        class="link"
        >location >></a
      >
      <div class="title3">China Office</div>
      <div class="title2">56 Zhenshi Road, Wuxi, Jiangsu, China</div>
      <a
        href="https://www.google.de/maps/place/Zhen+Shi+Lu,+Huishan+Qu,+Wuxi+Shi,+Jiangsu+Sheng,+China/@31.6478305,120.2627085,17.54z/data=!4m5!3m4!1s0x35b38c6e4eb74815:0xddb824e9120f4631!8m2!3d31.6493026!4d120.2640486?hl=en"
        target="view_window"
        class="link"
        >location >></a
      >
    </div>
    <!-- Drop -->
    <div style="padding: 0 1rem">
      <div class="title">Drop us a message</div>
      <Form ref="form" :model="form" :rules="rule">
        <FormItem prop="name">
          <Input
            size="large"
            class="input"
            v-model="form.name"
            placeholder="Name*"
          />
        </FormItem>
        <FormItem prop="email">
          <Input
            size="large"
            class="input"
            v-model="form.email"
            placeholder="Email Address*"
          />
        </FormItem>
        <FormItem prop="title">
          <Input
            size="large"
            class="input"
            v-model="form.title"
            placeholder="Subject*"
          />
        </FormItem>
        <FormItem prop="choice">
          <Select
            size="large"
            v-model="form.choice"
            class="input"
            placeholder="General Enquiries"
          >
            <Option value="Partnership Opportunities"
              >Partnership Opportunities</Option
            >
            <Option value="Sales Enquiry">Sales Enquiry</Option>
            <Option value="Product Enquiry">Product Enquiry</Option>
            <Option value="Articles Contribution">Articles Contribution</Option>
            <Option value="Feedback">Feedback</Option>
          </Select>
        </FormItem>
        <FormItem prop="text">
          <Input
            placeholder="Type your message here"
            size="large"
            class="input"
            v-model="form.text"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 5 }"
            show-word-limit
            maxlength="255"
          />
        </FormItem>
        <FormItem>
          <Button size="large" type="primary" @click="send()">Send</Button>
        </FormItem>
      </Form>
    </div>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mHeader from "../components/mHeader";
import mFooter from "../components/mFooter";
export default {
  data() {
    return {
      banner: "null",
      banner2: "null",
      banner3: "null",
      banner4: "null",
      banner5: "null",
      banner6: "null",
      picUrlaaa: "http://47.241.73.160:80/shop/images",
      historyPic: null,
      storyPic: null,
      video: null,
      form: {
        name: null,
        email: null,
        title: null,
        choice: null,
        text: null,
      },
      rule: {
        name: [
          {
            required: true,
            message: "Please fill in the name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please fill in the email",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "Please fill in the subject",
            trigger: "blur",
          },
        ],
        choice: [
          {
            required: true,
            message: "Please select the general enquiries",
            trigger: "change",
          },
        ],
        text: [
          {
            required: true,
            message: "Please type your message",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getInfo();
  },
  components: {
    mHeader,
    mFooter,
  },
  methods: {
    async getInfo() {
      const { data: res } = await this.$http.get("/polo/index/index");
      if (res.banner[0].bannerPic != "null") {
        this.banner = this.picUrlaaa + res.banner[0].bannerPic;
      }
      if (res.banner[1].bannerPic != "null") {
        this.banner2 = this.picUrlaaa + res.banner[1].bannerPic;
      }
      if (res.banner[2].bannerPic != "null") {
        this.banner3 = this.picUrlaaa + res.banner[2].bannerPic;
      }
      if (res.banner[3].bannerPic != "null") {
        this.banner4 = this.picUrlaaa + res.banner[3].bannerPic;
      }
      if (res.banner[4].bannerPic != "null") {
        this.banner5 = this.picUrlaaa + res.banner[4].bannerPic;
      }
      if (res.banner[5].bannerPic != "null") {
        this.banner6 = this.picUrlaaa + res.banner[5].bannerPic;
      }
      this.historyPic = res.newsList[0].newsList[0].picUrl;
      this.storyPic = res.newsList[1].newsList[0].picUrl;
      this.video = res.video[0].video;
      // this.productList = res.productList;
      localStorage.setItem(
        "history",
        JSON.stringify(res.newsList[0].newsList[0])
      );
      localStorage.setItem("video", JSON.stringify(res.video));
      localStorage.setItem(
        "newsList",
        JSON.stringify(res.newsList[1].newsList)
      );
      // this.Carousel = true;
    },
    goPage(e) {
      switch (e) {
        case 1:
          this.$router.push("/mHome");
          break;
        case 2:
          this.$router.push("/mHistory");
          break;
        case 3:
          this.$router.push("/mProducts");
          break;
        case 4:
          this.$router.push("/mStoriesList");
          break;
        case 5:
          this.$router.push("/mVideo");
          this.arrow = false;
          break;
      }
    },
    send() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "/polo/index/sendMail",
            this.form
          );
          if (res.code == 0) {
            this.form = this.$options.data().form;
            return this.$Message.success("Success");
          } else {
            return this.$Message.error("Fail");
          }
        } else {
          this.$Message.error("Fail!");
        }
      });
      // const { data: res } = await this.$http.post(
      //   "/polo/index/sendMail",
      //   this.form
      // );
    },
  },
};
</script>

<style scoped lang="less">
.content {
  padding-top: 4rem;
}
html,
body {
  overflow: auto;
  height: 100%;
}
.banner {
  object-fit: cover;
  width: 100%;
  height: 15rem;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5f1985;
  margin-top: 2rem;
}
.title2 {
  width: 100%;
  text-align: center;
  font-size: 1rem;
}
.title3 {
  color: #5f1985;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  margin-top: 1rem;
}
.more {
  width: 100%;
  text-align: center;
  color: #5f1985;
  font-size: 1rem;
}
.link {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 1rem;
}
</style>
