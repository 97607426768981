<template>
  <div class="content" style="margin: 60px">
    <div style="width: 1200px">
      <Button @click="logout" style="width: 120px; margin-bottom: 30px"
        >退出登录</Button
      >
      <Tabs type="card">
        <TabPane label="图片和视频"
          ><div style="float: left; width: 300px">
            <div>主页图片1上传：</div>
            <Upload
              :action="upLoadUrl"
              :on-success="handlUpload1"
              style="display: inline-block; margin-right: 30px"
            >
              <Button icon="ios-cloud-upload-outline"
                >Upload files</Button
              > </Upload
            ><Button style="display: inline-block" @click="deleteBanner(1)"
              >删除图片1</Button
            >
            <div>主页图片2上传：</div>
            <Upload
              :action="upLoadUrl"
              :on-success="handlUpload2"
              style="display: inline-block; margin-right: 30px"
            >
              <Button icon="ios-cloud-upload-outline">Upload files</Button>
            </Upload>
            <Button style="display: inline-block" @click="deleteBanner(2)"
              >删除图片2</Button
            >
            <div>主页图片3上传：</div>
            <Upload
              :action="upLoadUrl"
              :on-success="handlUpload3"
              style="display: inline-block; margin-right: 30px"
            >
              <Button icon="ios-cloud-upload-outline">Upload files</Button>
            </Upload>
            <Button style="display: inline-block" @click="deleteBanner(3)"
              >删除图片3</Button
            >
            <div>主页图片4上传：</div>
            <Upload
              :action="upLoadUrl"
              :on-success="handlUpload4"
              style="display: inline-block; margin-right: 30px"
            >
              <Button icon="ios-cloud-upload-outline">Upload files</Button>
            </Upload>
            <Button style="display: inline-block" @click="deleteBanner(4)"
              >删除图片4</Button
            >
            <div>主页图片5上传：</div>
            <Upload
              :action="upLoadUrl"
              :on-success="handlUpload5"
              style="display: inline-block; margin-right: 30px"
            >
              <Button icon="ios-cloud-upload-outline">Upload files</Button>
            </Upload>
            <Button style="display: inline-block" @click="deleteBanner(5)"
              >删除图片5</Button
            >
            <div>主页图片6上传：</div>
            <Upload
              :action="upLoadUrl"
              :on-success="handlUpload6"
              style="display: inline-block; margin-right: 30px"
            >
              <Button icon="ios-cloud-upload-outline">Upload files</Button>
            </Upload>
            <Button style="display: inline-block" @click="deleteBanner(6)"
              >删除图片6</Button
            >
          </div>
          <div style="float: left; width: 900px">
            <div>
              <div style="display: inline-block">视频上传：</div>
              <Upload
                style="display: inline-block"
                :action="upLoadUrl"
                :on-success="handlUploadVideo"
              >
                <Button icon="ios-cloud-upload-outline">Upload files</Button>
              </Upload>
              <span
                style="display: inline-block; width: 30px; margin-left: 30px"
                >编号</span
              >
              <Input
                disabled
                v-model="video.id"
                placeholder="编号"
                style="margin: 5px 20px; width: 60px"
              />
              <span
                style="display: inline-block; width: 30px; margin-left: 30px"
                >排序</span
              >
              <Input
                v-model="video.sortNo"
                placeholder="序号"
                style="margin: 5px 20px; width: 60px"
              />
              <span
                style="display: inline-block; width: 30px; margin-left: 30px"
                >标题</span
              >
              <Input
                v-model="video.title"
                placeholder="标题"
                style="margin: 5px 20px; width: 240px"
              />
            </div>
            <div>
              <span style="display: inline-block; width: 30px">简介</span>
              <Input
                v-model="video.detail"
                type="textarea"
                placeholder="简介"
                style="margin: 5px 20px; width: 300px"
              />
              <Button
                style="display: inline-block; margin-left: 30px"
                @click="addVideo()"
                >新增</Button
              >
              <Button
                style="display: inline-block; margin-left: 30px"
                @click="saveVideo()"
                type="primary"
                >保存</Button
              >
            </div>
            <Table
              style="margin-top: 30px"
              :columns="columnsVideoTitle"
              :data="videoList"
            ></Table>
          </div>
        </TabPane>
        <TabPane label="商品分类">
          商品分类名称：
          <Input
            v-model="cateName"
            placeholder="分类名称"
            style="width: 300px"
          />
          <Button style="margin-left: 30px" type="primary" @click="addCate"
            >新增</Button
          >
          <Table
            style="margin-top: 30px"
            :columns="columnsTitle"
            :data="cateList"
          ></Table>
        </TabPane>
        <TabPane label="商品列表">
          <div class="productAdd">
            <div>
              <div>
                商品图片上传：<Upload
                  :action="upLoadUrl"
                  :on-success="handlProductUpload"
                >
                  <Button icon="ios-cloud-upload-outline">Upload files</Button>
                </Upload>
              </div>
              <div v-if="product.picUrl != ''">
                <img
                  v-if="product.picUrl != null"
                  :src="picUrlaaa + product.picUrl"
                  style="height: 100px; width: 100px; object-fit: cover"
                />
              </div>
              <div>
                <span style="display: inline-block; width: 90px">产品编号</span>
                <Input
                  disabled
                  v-model="product.id"
                  placeholder="产品编号"
                  style="margin: 5px 20px; width: 180px"
                />
              </div>
              <div>
                <span style="display: inline-block; width: 90px">产品名称</span>
                <Input
                  v-model="product.name"
                  placeholder="产品名称"
                  style="margin: 5px 20px; width: 180px"
                />
              </div>
              <div>
                <span style="display: inline-block; width: 90px">产品排序</span>
                <Input
                  v-model="product.sortNo"
                  placeholder="产品名称"
                  style="margin: 5px 20px; width: 180px"
                />
              </div>
              <div>
                <span style="display: inline-block; width: 90px"
                  >Specification</span
                >
                <Input
                  v-model="product.capacity"
                  placeholder="SPEC"
                  style="margin: 5px 20px; width: 180px"
                />
              </div>
              <div>
                <span style="display: inline-block; width: 90px"
                  >Viscosity</span
                >
                <Input
                  v-model="product.viscosity"
                  placeholder="Viscosity"
                  style="margin: 5px 20px; width: 180px"
                />
              </div>
              <div>
                <span style="display: inline-block; width: 90px"
                  >Packaging</span
                >
                <Input
                  v-model="product.packagin"
                  placeholder="Packaging"
                  style="margin: 5px 20px; width: 180px"
                />
              </div>
              <div>
                <span style="display: inline-block; width: 90px">产品类别</span>
                <Select
                  v-model="product.categoryId"
                  style="margin: 5px 20px; width: 180px"
                >
                  <Option
                    v-for="item in cateList"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
              </div>
            </div>
            <div class="editor" id="editor" ref="editor">编辑详情</div>
            <Button style="margin-left: 30px" type="primary" @click="addProduct"
              >确认</Button
            >
          </div>
          <Table
            height="600"
            size="small"
            style="margin-top: 30px"
            :columns="columnsTitle2"
            :data="productList"
          ></Table>
        </TabPane>
        <TabPane label="新闻列表">
          <div class="productAdd">
            <div>
              <div>
                新闻头图上传：<Upload
                  :action="upLoadUrl"
                  :on-success="handlNewsUpload"
                >
                  <Button icon="ios-cloud-upload-outline">Upload files</Button>
                </Upload>
              </div>
              <div v-if="news.picUrl != ''">
                <img
                  v-if="news.picUrl != null"
                  :src="picUrlaaa + news.picUrl"
                  style="height: 100px; width: 100px; object-fit: cover"
                />
              </div>
              <div>
                新闻标题
                <Input
                  v-model="news.title"
                  placeholder="新闻标题"
                  style="margin: 5px 20px; width: 180px"
                />
              </div>
              <div>
                新闻序号
                <Input
                  v-model="news.sortNo"
                  placeholder="新闻序号"
                  style="margin: 5px 20px; width: 180px"
                />
              </div>
              <div>
                新闻插入图片：<Upload
                  :action="upLoadUrl"
                  :on-success="handlNewsImgUpload"
                >
                  <Button icon="ios-cloud-upload-outline">Upload files</Button>
                </Upload>
                <div style="width: 240px; word-wrap: break-word">
                  地址{{ imgUrl }}
                </div>
              </div>
            </div>
            <div class="editor" id="editor2" ref="editor2">编辑详情</div>
            <Button style="margin-left: 30px" type="primary" @click="addNews"
              >确认</Button
            >
          </div>
          <Table
            style="margin-top: 30px"
            :columns="columnsNewsTitle"
            :data="newsList"
          ></Table>
        </TabPane>
      </Tabs>
    </div>
  </div>
</template>

<script>
import E from "wangeditor";
import xss from "xss";
const editor = new E("#editor"); //editor实例化
const editor2 = new E("#editor2"); //editor实例化
export default {
  data() {
    return {
      columnsTitle: [
        {
          title: "编号",
          key: "id",
          align: "center",
        },
        {
          title: "名称",
          key: "name",
          align: "center",
        },
        {
          title: "Action",
          key: "action",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.removeCate(params);
                    },
                  },
                },
                "Delete"
              ),
            ]);
          },
        },
      ],
      columnsTitle2: [
        {
          title: "编号",
          key: "id",
          align: "center",
        },
        {
          title: "名称",
          key: "name",
          align: "center",
        },
        {
          title: "类别",
          key: "categoryName",
          align: "center",
        },
        {
          title: "viscosity",
          key: "viscosity",
          align: "center",
        },
        {
          title: "SPEC",
          key: "capacity",
          align: "center",
        },
        {
          title: "packaging",
          key: "packagin",
          align: "center",
        },
        {
          title: "排序",
          key: "sortNo",
          align: "center",
        },
        {
          title: "Action",
          key: "action",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.editProduct(params);
                    },
                  },
                },
                "Edit"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.removeProduct(params);
                    },
                  },
                },
                "Delete"
              ),
            ]);
          },
        },
      ],
      columnsNewsTitle: [
        {
          title: "编号",
          key: "id",
          align: "center",
        },
        {
          title: "标题",
          key: "title",
          align: "center",
        },
        {
          title: "排序",
          key: "sortNo",
          align: "center",
        },
        {
          title: "Action",
          key: "action",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.editNews(params);
                    },
                  },
                },
                "Edit"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.removeNews(params);
                    },
                  },
                },
                "Delete"
              ),
            ]);
          },
        },
      ],
      columnsVideoTitle: [
        {
          title: "编号",
          key: "id",
          align: "center",
        },
        {
          title: "标题",
          key: "title",
          align: "center",
        },
        {
          title: "简介",
          key: "detail",
          align: "center",
        },
        {
          title: "排序",
          key: "sortNo",
          align: "center",
        },
        {
          title: "Action",
          key: "action",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.editVideo(params);
                    },
                  },
                },
                "Edit"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.removeVideo(params);
                    },
                  },
                },
                "Delete"
              ),
            ]);
          },
        },
      ],
      upLoadUrl: "http://47.241.73.160:80/shop/polo/index/fileUpLoad",
      picUrlaaa: "http://47.241.73.160:80/shop/images",
      imgUrl: null,
      cateName: "",
      cateList: [],
      videoList: [],
      editor: "",
      product: {
        id: null,
        name: null,
        describe: "describe",
        viscosity: null,
        capacity: null,
        packagin: null,
        categoryId: null,
        picUrl: null,
        detail: null,
        sortNo: null,
      },
      productList: [],
      news: {
        title: null,
        categoryId: 6,
        detail: null,
        picUrl: null,
        sortNo: null,
      },
      newsList: [],
      video: {
        id: null,
        video: null,
        sortNo: null,
        title: null,
        detail: null,
      },
    };
  },
  created() {
    this.getCate();
    this.getProduct();
    this.getNews();
    this.getInfo();
  },
  mounted() {
    this.editorConfig();
  },
  updated() {
    // this.editorConfig(); //数据更新之后初始化富文本
  },
  methods: {
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/control/login");
    },
    async uploadimg(img) {
      const { data: res } = await this.$http.post(
        "http://47.241.73.160:80/shop/polo/index/fileUpLoad",
        img
      );
    },
    async getInfo() {
      const { data: res } = await this.$http.get("/polo/index/index");
      this.videoList = res.video;
    },
    editorConfig() {
      const that = this;
      editor.create();
      editor2.create();
      editor.config.onchange = function (newHtml) {
        // function escapeHtml(html) {
        //   return html.replace(/</g, "&lt;").replace(/>/g, "&gt;");
        // }
        that.product.detail = newHtml;
      };
      editor2.config.onchange = function (newHtml) {
        that.news.detail = newHtml;
      };
      //   //实时获取富文本内容
      //   that.editor.customConfig.onchange = function (text) {
      //     that.artical_content = text;
      //   };
      //以上配置完成之后调用其create()方法进行创建
      //   that.editor.txt.html(that.artical_content); //设置内容在富文本中(必须在update中调用该方法)
    },
    handlProductUpload(response, file, fileList) {
      this.product.picUrl = response.msg;
    },
    handlNewsUpload(response, file, fileList) {
      this.news.picUrl = response.msg;
    },
    handlNewsImgUpload(response, file, fileList) {
      this.imgUrl = this.picUrlaaa + response.msg;
    },
    escapeHtml(html) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return html.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
      });
    },
    async handlUpload1(response, file, fileList) {
      const { data: res } = await this.$http.post("/polo/index/saveOrUpdate", {
        id: 1,
        bannerPic: response.msg,
      });
    },
    async handlUpload2(response, file, fileList) {
      const { data: res } = await this.$http.post("/polo/index/saveOrUpdate", {
        id: 2,
        bannerPic: response.msg,
      });
    },
    async handlUpload3(response, file, fileList) {
      const { data: res } = await this.$http.post("/polo/index/saveOrUpdate", {
        id: 3,
        bannerPic: response.msg,
      });
    },
    async handlUpload4(response, file, fileList) {
      const { data: res } = await this.$http.post("/polo/index/saveOrUpdate", {
        id: 4,
        bannerPic: response.msg,
      });
    },
    async handlUpload5(response, file, fileList) {
      const { data: res } = await this.$http.post("/polo/index/saveOrUpdate", {
        id: 5,
        bannerPic: response.msg,
      });
    },
    async handlUpload6(response, file, fileList) {
      const { data: res } = await this.$http.post("/polo/index/saveOrUpdate", {
        id: 6,
        bannerPic: response.msg,
      });
    },
    async deleteBanner(e) {
      const { data: res } = await this.$http.post("/polo/index/saveOrUpdate", {
        id: e,
        bannerPic: "null",
      });
    },
    handlUploadVideo(response, file, fileList) {
      this.video.video = response.msg;
    },
    async saveVideo() {
      const { data: res } = await this.$http.post(
        "/polo/video/saveOrUpdate",
        this.video
      );
      if (res.code == 0) {
        this.$Message.success("保存成功");
        this.getInfo();
        this.video = this.$options.data().video;
      } else {
        this.$Message.error("保存失败，" + res.msg);
      }
    },
    async addVideo() {
      this.video.id = null;
      const { data: res } = await this.$http.post(
        "/polo/video/saveOrUpdate",
        this.video
      );
      if (res.code == 0) {
        this.$Message.success("保存成功");
        this.getInfo();
      } else {
        this.$Message.error("保存失败，" + res.msg);
      }
    },
    editVideo(e) {
      this.video.id = e.row.id;
      this.video.sortNo = e.row.sortNo;
      this.video.title = e.row.title;
      this.video.detail = e.row.detail;
    },
    async addCate() {
      const { data: res } = await this.$http.post(
        "/polo/category/saveOrUpdate",
        {
          type: 1,
          name: this.cateName,
        }
      );
      this.getCate();
    },
    async getCate() {
      const { data: res } = await this.$http.get("/polo/category/list", {
        params: {
          type: 1,
          pageSize: 100,
        },
      });
      this.cateList = res.data.list;
    },
    async removeCate(e) {
      const { data: res } = await this.$http.get(
        "/polo/category/delete/" + e.row.id
      );
      this.getCate();
    },
    async getProduct() {
      const { data: res } = await this.$http.get("/polo/product/list", {
        params: {
          pageSize: 100,
        },
      });
      if (res.code == 0) {
        this.productList = res.data.list;
      } else if (res.code == 401) {
        this.logout();
      } else {
        return this.$Message.error(res.msg);
      }
    },
    async addProduct() {
      if (this.product.categoryId == null) {
        return this.$Message.error("请选择分类");
      }
      const { data: res } = await this.$http.post(
        "/polo/product/saveOrUpdate",
        this.product
      );
      if (res.code == 0) {
        this.product = this.$options.data().product;
        editor.txt.clear();
        this.getProduct();
        return this.$Message.success("保存成功");
      } else {
        return this.$Message.error("保存失败");
      }
    },
    async removeProduct(e) {
      const { data: res } = await this.$http.get(
        "/polo/product/delete/" + e.row.id
      );
      this.getProduct();
    },
    editProduct(e) {
      this.product.id = e.row.id;
      this.product.name = e.row.name;
      this.product.viscosity = e.row.viscosity;
      this.product.capacity = e.row.capacity;
      this.product.packagin = e.row.packagin;
      this.product.categoryId = e.row.categoryId;
      this.product.picUrl = e.row.picUrl;
      this.product.sortNo = e.row.sortNo;
      editor.txt.html(this.escapeHtml(e.row.detail));
    },
    editNews(e) {
      this.news.id = e.row.id;
      this.news.title = e.row.title;
      this.news.picUrl = e.row.picUrl;
      this.news.sortNo = e.row.sortNo;
      this.news.categoryId = e.row.categoryId;
      editor2.txt.html(this.escapeHtml(e.row.detail));
    },
    async getNews() {
      const { data: res } = await this.$http.get("/polo/news/list", {
        params: {
          pageSize: 100,
        },
      });
      this.newsList = res.data.list;
    },
    async addNews() {
      const { data: res } = await this.$http.post(
        "/polo/news/saveOrUpdate",
        this.news
      );
      if (res.code == 0) {
        this.news = this.$options.data().news;
        editor2.txt.clear();
        this.getNews();
        return this.$Message.success("保存成功");
      } else {
        return this.$Message.error("保存失败");
      }
    },
    async removeNews(e) {
      const { data: res } = await this.$http.get(
        "/polo/news/delete/" + e.row.id
      );
      this.getNews();
    },
    async removeVideo(e) {
      const { data: res } = await this.$http.get(
        "/polo/video/delete/" + e.row.id
      );
      this.getInfo();
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  align-content: center;
  justify-content: center;
}
.editor {
  width: 600px;
}
.productAdd {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
