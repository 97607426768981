<template>
  <div class="Footer">-- Performance You Can Trust --</div>
</template>

<script>
export default {};
</script>

<style>
.Footer {
  width: 100%;
  background-color: #5f1985;
  color: #fff;
  text-align: center;
  height: 2rem;
  line-height: 2rem;
}
</style>
