import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Control from "../views/Control.vue";
import Login from "../views/Login.vue";
import Product from "../views/Product.vue";
import mHome from "../mobile/mHome.vue";
import mHistory from "../mobile/mHistory.vue";
import mProducts from "../mobile/mProducts.vue";
import mProductsList from "../mobile/mProductsList.vue";
import mProductInfo from "../mobile/mProductInfo.vue";
import mStories from "../mobile/mStories.vue";
import mStoriesList from "../mobile/mStoriesList.vue";
import mVideo from "../mobile/mVideo.vue";
import mVideoInfo from "../mobile/mVideoInfo.vue";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "*",
    redirect: "/home",
  },
  {
    path: "/control",
    name: "Control",
    component: Control,
  },
  {
    path: "/control/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/mHome",
    name: "mHome",
    component: mHome,
  },
  {
    path: "/mHistory",
    name: "mHistory",
    component: mHistory,
  },
  {
    path: "/mProducts",
    name: "mProducts",
    component: mProducts,
  },
  {
    path: "/mProductsList",
    name: "mProductsList",
    component: mProductsList,
  },
  {
    path: "/mProductInfo",
    name: "mProductInfo",
    component: mProductInfo,
  },
  {
    path: "/mStories",
    name: "mStories",
    component: mStories,
  },
  {
    path: "/mStoriesList",
    name: "mStoriesList",
    component: mStoriesList,
  },
  {
    path: "/mVideo",
    name: "mVideo",
    component: mVideo,
  },
  {
    path: "/mVideoInfo",
    name: "mVideoInfo",
    component: mVideoInfo,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  //获取token
  const tokenStr = window.sessionStorage.getItem("token");
  if (!tokenStr && to.path === "/control") return next("/control/login");
  next();

  if (to.path === "/") {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      router.push({ name: "mHome" });
    } else {
      router.push({ name: "Home" });
    }
  }
});

export default router;
