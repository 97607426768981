<template>
  <div class="content">
    <mHeader></mHeader>
    <div class="title">Videos</div>
    <div v-for="(video,i) in videoList" :key="i" class="videoList">
      <div class="title2">{{ video.title }}</div>
      <video
        style="width: 100%;"
        :src="picUrlaaa + video.video"
        controls="controls"
      />
      <div class="detail">{{ video.detail }}</div>
      <div class="more" @click="goPage(i)">Learn more ></div>
    </div>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mHeader from "../components/mHeader";
import mFooter from "../components/mFooter";
export default {
  data() {
    return {
      videoList: null,
      picUrlaaa: "http://47.241.73.160:80/shop/images",
    };
  },
  components: {
    mHeader,
    mFooter,
  },
  created() {
    this.videoList = JSON.parse(localStorage.getItem("video"));
  },
  methods:{
    goPage(e){
      console.log(e)
      localStorage.setItem("videoNo", e);
      this.$router.push("/mVideoInfo");
    }
  }
};
</script>

<style scoped lang="less">
html,
body {
  overflow: auto;
  height: 100%;
}
.content {
  padding-top: 4rem;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5f1985;
}
.title2 {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: #5f1985;
  margin-bottom: 1rem;
}
.detail {
  padding: 0 1rem;
  line-height: 1.5rem;
  max-height: 3rem;
  table-layout: fixed;
  word-wrap: break-word;
  word-break: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.more {
  width: 100%;
  text-align: center;
  color: #5f1985;
  font-size: 1rem;
}
.videoList {
  margin: 2rem 0;
}
</style>
