<template>
  <div>
    <Header
      style="
        height: 60px;
        background-color: #ffffff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
      "
      ><img style="float: left; height: 58px" src="../assets/logo.png" />
      <Menu
        style="float: right"
        mode="horizontal"
        active-name="3"
        @on-select="goHome"
      >
        <MenuItem name="1">Home</MenuItem>
        <MenuItem name="2">Brand History</MenuItem>
        <MenuItem name="3">Products</MenuItem>
        <MenuItem name="4">Stories</MenuItem>
        <MenuItem name="5">Video</MenuItem>
        <MenuItem name="6">Contact</MenuItem>
      </Menu>
    </Header>
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        margin-top: 120px;
      "
    >
      <!-- <img
      style="height: 58px; margin: 30px 0"
      src="../assets/logo.png"
      @click="goHome"
    /> -->
      <div style="width: 1200px; height: 550px；">
        <div class="productInfo">
          <img
            style="
              width: 450px;
              height: 450px;
              object-fit: contain;
              margin-left: 60px;
              margin-top: 60px;
            "
            :src="picUrlaaa + info.picUrl"
          />
          <div
            style="
              width: 600px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-content: center;
            "
          >
            <div style="font-size: 30px; color: #5f1985">
              POLO<i style="font-size: 16px; vertical-align: super">®</i
              >{{ info.name }}
            </div>
            <div style="font-size: 18px">
              <span
                style="display: inline-block; width: 120px"
                v-if="info.capacity != null"
                >Specification:</span
              >{{ info.capacity }}
            </div>
            <div style="font-size: 18px">
              <span
                style="display: inline-block; width: 120px"
                v-if="info.viscosity != null"
                >Viscosity:</span
              >{{ info.viscosity }}
            </div>
            <div style="font-size: 18px">
              <span
                style="display: inline-block; width: 120px"
                v-if="info.packagin != null"
                >Packaging: </span
              >{{ info.packagin }}
            </div>
            <div
              style="
                width: 600px;
                margin-top: 10px;
                max-height: 380px;
                overflow: auto;
              "
              v-html="escapeHtml(info.detail)"
            ></div>
          </div>
        </div>
      </div>
      <div style="width: 1200px">
        <Tabs :value="tabname" style="" @on-click="selectTab">
          <!-- <TabPane label="ALL" name="name1">
          <div class="allproductList">
            <div v-for="pro in allproductList" :key="pro.id">
              <img
                style="width: 240px; height: 240px; object-fit: cover"
                :src="picUrlaaa + pro.picUrl"
                @click="openInfo(pro)"
              />
              <div style="width: 240px; text-align: center; color: #5f1985">
                {{ pro.name }}
              </div>
            </div>
          </div>
        </TabPane> -->
          <TabPane
            v-for="cate in productList"
            :key="cate.categoryId"
            :label="cate.name"
            :name="cate.name"
            class="productList"
          >
            <div v-for="pro in cate.productList" :key="pro.id" class="pro2">
              <img
                @click="openInfo(pro)"
                style="
                  width: 240px;
                  height: 160px;
                  object-fit: contain;
                  margin-top: 12px;
                "
                :src="picUrlaaa + pro.picUrl"
              />
              <div
                class="proinfo"
                :style="
                  cate.name == 'Motorcycles' ? 'width: 130px' : 'width: 240px'
                "
              >
                POLO<i
                  style="
                    font-size: 10px;
                    vertical-align: text-top;
                    line-height: 10px;
                  "
                  >®</i
                >{{ pro.name }}
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal1: false,
      picUrlaaa: "http://47.241.73.160:80/shop/images",
      productList: [],
      allproductList: [],
      info: { detail: "" },
      tabname: null,
    };
  },
  mounted() {},
  created() {
    this.setCate(this.$route.params.id);
  },
  methods: {
    async getInfo(e) {
      const { data: res } = await this.$http.get("/polo/index/index");
      if (this.productList.length == 0) {
        res.productList.map((item) => {
          item.productList = item.productList;
          this.productList.push(item);
        });
      }
      this.info = this.productList[e].productList[0];
    },
    openInfo(e) {
      this.modal1 = true;
      this.info = e;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    escapeHtml(html) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return html.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
        return arrEntities[t];
      });
    },
    goHome(e) {
      this.$router.push({
        name: "Home",
        params: {
          id: e,
        },
      });
    },
    selectTab(e) {
      switch (e) {
        case undefined:
          this.getInfo(0);
          break;
        case "Passenger Cars":
          this.getInfo(0);
          break;
        case "Motorcycles":
          this.getInfo(1);
          break;
        case "Light Duty Diesel & Pickup Trucks":
          this.getInfo(2);
          break;
        case "Heavy Duty Trucks & Buses":
          this.getInfo(3);
          break;
        case "Transmission Fluids":
          this.getInfo(4);
          break;
        case "Industrial Oils":
          this.getInfo(5);
          break;
      }
    },
    setCate(e) {
      switch (e) {
        case undefined:
          this.tabname = "Passenger Cars";
          this.getInfo(0);
          break;
        case 1:
          this.tabname = "Passenger Cars";
          this.getInfo(0);
          break;
        case 2:
          this.tabname = "Motorcycles";
          this.getInfo(1);
          break;
        case 3:
          this.tabname = "Light Duty Diesel & Pickup Trucks";
          this.getInfo(2);
          break;
        case 4:
          this.tabname = "Heavy Duty Trucks & Buses";
          this.getInfo(3);
          break;
        case 5:
          this.tabname = "Transmission Fluids";
          this.getInfo(4);
          break;
        case 6:
          this.tabname = "Industrial Oils";
          this.getInfo(5);
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
.productList {
  width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.ivu-tabs-tab {
  font-size: 16px;
  font-weight: bold;
}
.allproductList {
  width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
}
.productInfo {
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 30px;
}
.pro2 {
  text-align: center;
  cursor: pointer;
}
.pro2 img {
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.pro2:hover img {
  transform: scale(1.1);
}
.pro2:hover .proinfo {
  color: #5f1985;
  font-weight: bold;
}
.proinfo {
  text-align: center;
  margin: auto;
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
}
/deep/.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu-active,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item:hover,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu:hover {
  background-color: #5f1985;
  color: #fff;
  border-radius: 64px;
}
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu {
  height: 30px;
  line-height: 30px;
  margin-top: 15px;
  border: none;
}
/deep/.ivu-tabs-nav .ivu-tabs-tab {
  padding-left: 28px;
  padding-right: 28px;
}
</style>
