<template>
  <div class="content">
    <mHeader></mHeader>
    <img
      v-if="title == 'Passenger Cars'"
      style="width: 100%;"
      src="../assets/passenger car.jpg"
    />
    <img
      v-if="title == 'Motorcycles'"
      style="width: 100%;"
      src="../assets/motorcycle.jpg"
    />
    <img
      v-if="title == 'Light Duty Diesel & Pickup Trucks'"
      style="width: 100%;"
      src="../assets/pickup.jpg"
    />
    <img
      v-if="title == 'Heavy Duty Trucks & Buses'"
      style="width: 100%;"
      src="../assets/heavy duty.jpg"
    />
    <img
      v-if="title == 'Transmission Fluids'"
      style="width: 100%;"
      src="../assets/transmission.jpg"
    />
    <img
      v-if="title == 'Industrial Oils'"
      style="width: 100%;"
      src="../assets/industrial.jpg"
    />
    <div class="title">{{ title }}</div>
    <div
      v-for="item in productList"
      style="padding:2rem 1rem"
      @click="openInfo(item)"
    >
      <img :src="picUrlaaa + item.picUrl" style="width:100%;max-height:16rem;object-fit: contain" />
      <div class="name">
        POLO<i
          style="
                    font-size:1rem;
                    vertical-align: text-top;
                    line-height: 1rem;
                  "
          >®</i
        >{{ item.name }}
      </div>
      <div class="infoList">
        <span class="info" v-if=" item.capacity != null">Specification:</span
        ><span class="info2">{{ item.capacity }}</span>
      </div>
      <div class="infoList">
        <span class="info" v-if=" item.capacity != null">Viscosity:</span
        ><span class="info2">{{ item.viscosity }}</span>
      </div>
      <div class="infoList" v-if=" item.id != 86 && item.id !=85 && item.id !=84 && item.id !=83">
        <span class="info" >Packaging:</span
        ><span class="info2">{{ item.packagin }}</span>
      </div>
      <div class="more">Learn more ></div>
    </div>
    <mGoProducts></mGoProducts>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mHeader from "../components/mHeader";
import mFooter from "../components/mFooter";
import mGoProducts from "../components/mGoProducts";
export default {
  data() {
    return {
      productList: [],
      title: null,
      picUrlaaa: "http://47.241.73.160:80/shop/images",
    };
  },
  components: {
    mHeader,
    mFooter,
    mGoProducts
  },
  created() {
    this.title = localStorage.getItem("productCate");
    this.getInfo(this.title);
  },
  methods: {
    async getInfo(e) {
      const { data: res } = await this.$http.get("/polo/index/index");
      res.productList.forEach((item) => {
        if (item.name == e) {
          this.productList = item.productList;
        }
      });
    },
    openInfo(e) {
      localStorage.setItem("productInfo", JSON.stringify(e));
      this.$router.push("/mProductInfo");
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style scoped lang="less">
.content{
  padding-top: 4rem;
}
html, body {  
    overflow: auto;  
    height: 100%;  
}
.title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5f1985;
}
.name {
  width: 100%;
  text-align: center;
  color: #5f1985;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.more {
  width: 100%;
  text-align: center;
  color: #5f1985;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}
.infoList {
  display: flex;
  flex-direction: row;
}
.info {
  display: inline-block;
  width: 6rem;
}
.info2 {
  display: inline-block;
  width: 12rem;
}
</style>
