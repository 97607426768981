<template>
  <div class="menu">
    <div @click="goPage('Passenger Cars')" class="menu-list">
      Passenger Cars
    </div>
    <div @click="goPage('Motorcycles')" class="menu-list">Motorcycles</div>
    <div @click="goPage('Light Duty Diesel & Pickup Trucks')" class="menu-list">
      Light Duty Diesel & Pickup Trucks
    </div>
    <div @click="goPage('Heavy Duty Trucks & Buses')" class="menu-list">
      Heavy Duty Trucks & Buses
    </div>
    <div @click="goPage('Transmission Fluids')" class="menu-list">
      Transmission Fluids
    </div>
    <div @click="goPage('Industrial Oils')" class="menu-list">
      Industrial Oils
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goPage(e) {
      localStorage.setItem("productCate", e);
      this.$router.push("/mProductsList");
      window.location.reload();
    },
  },
};
</script>

<style scoped lang="less">
.menu {
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #666;
  padding: 0 1rem;
  margin-bottom: 2rem;
}
.menu-list {
  border-bottom: 1px solid #ddd;
  padding: 1rem 0;
  color: #5f1985;
  font-weight: bold;
  font-size: 1rem;
}
</style>
