<template>
  <div class="content">
    <mHeader></mHeader>
    <div class="title">{{ videoInfo.title }}</div>
    <video
      style="width: 100%;"
      :src="picUrlaaa + videoInfo.video"
      controls="controls"
    />
    <div class="detail">{{ videoInfo.detail }}</div>
    <div class="btnList">
      <Button type="primary" :disabled="last" @click="lastPage()"
        >< previous video</Button
      >
      <Button type="primary" :disabled="next" @click="nextPage()"
        >next video ></Button
      >
    </div>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mHeader from "../components/mHeader";
import mFooter from "../components/mFooter";
export default {
  data() {
    return {
      videoList: [],
      videoInfo: null,
      videoNo: null,
      last: false,
      next: false,
      picUrlaaa: "http://47.241.73.160:80/shop/images",
    };
  },
  components: {
    mHeader,
    mFooter,
  },
  created() {
    this.videoNo = Number(localStorage.getItem("videoNo"));
    this.videoList = JSON.parse(localStorage.getItem("video"));
    this.changeVideo(this.videoNo);
    this.changeBtn();
  },
  methods: {
    changeBtn() {
      if (this.videoNo == "0") {
        this.last = true;
        if (this.videoList.length ==1) {
          this.next = true;
        }
      } else if (this.videoNo == this.videoList.length - 1) {
        this.next = true;
      } else {
        this.last = false;
        this.next = false;
      }
    },
    changeVideo(e) {
      this.videoInfo = this.videoList[e];
    },
    lastPage() {
      this.videoNo = this.videoNo - 1;
      this.changeVideo(this.videoNo);
      this.changeBtn();
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    nextPage() {
      this.videoNo = this.videoNo + 1;
      this.changeVideo(this.videoNo);
      this.changeBtn();
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
  },
};
</script>

<style scoped lang="less">
html,
body {
  overflow: auto;
  height: 100%;
}
.content {
  padding-top: 4rem;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5f1985;
}
.title2 {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: #5f1985;
  margin-bottom: 1rem;
}
.detail {
  padding: 0 1rem;
  line-height: 1.5rem;
  word-wrap: break-word;
  word-break: normal;
  margin-bottom: 3rem;
}
.more {
  width: 100%;
  text-align: center;
  color: #5f1985;
  font-size: 1rem;
}
.videoList {
  margin: 2rem 0;
}
.btnList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 4rem 1rem;
}
</style>
