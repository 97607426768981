<template>
  <div class="content">
    <mHeader></mHeader>
    <img
      v-if="title == 'Passenger Cars'"
      style="width: 100%;"
      src="../assets/passenger car.jpg"
    />
    <img
      v-if="title == 'Motorcycles'"
      style="width: 100%;"
      src="../assets/motorcycle.jpg"
    />
    <img
      v-if="title == 'Light Duty Diesel & Pickup Trucks'"
      style="width: 100%;"
      src="../assets/pickup.jpg"
    />
    <img
      v-if="title == 'Heavy Duty Trucks & Buses'"
      style="width: 100%;"
      src="../assets/heavy duty.jpg"
    />
    <img
      v-if="title == 'Transmission Fluids'"
      style="width: 100%;"
      src="../assets/transmission.jpg"
    />
    <img
      v-if="title == 'Industrial Oils'"
      style="width: 100%;"
      src="../assets/industrial.jpg"
    />
    <div class="title">{{ title }}</div>
    <div style="padding:2rem 1rem">
      <img :src="picUrlaaa + product.picUrl" style="width:100%;max-height:16rem;object-fit: contain" />
      <div class="name">
        POLO<i
          style="
                    font-size:1rem;
                    vertical-align: text-top;
                    line-height: 1rem;
                  "
          >®</i
        >{{ product.name }}
      </div>
      <div class="infoList">
        <span class="info" v-if=" product.capacity!=null">Specification:</span
        ><span class="info2">{{ product.capacity }}</span>
      </div>
      <div class="infoList">
        <span class="info" v-if=" product.viscosity!=null">Viscosity:</span
        ><span class="info2">{{ product.viscosity }}</span>
      </div>
      <div class="infoList">
        <span class="info">Packaging:</span
        ><span class="info2">{{ product.packagin }}</span>
      </div>
      <div
        style="width: 100%;overflow: auto;margin-top:1rem"
        v-html="escapeHtml(product.detail)"
      ></div>
    </div>
    <mGoProducts></mGoProducts>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mHeader from "../components/mHeader";
import mFooter from "../components/mFooter";
import mGoProducts from "../components/mGoProducts";
export default {
  data() {
    return {
      product: null,
      title: null,
      picUrlaaa: "http://47.241.73.160:80/shop/images",
    };
  },
  components: {
    mHeader,
    mFooter,
    mGoProducts
  },
  created() {
    this.product = JSON.parse(localStorage.getItem("productInfo"));
    this.title = localStorage.getItem("productCate");
    console.log(this.product)
  },
  methods: {
    escapeHtml(html) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return html.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
        return arrEntities[t];
      });
    },
  },
};
</script>

<style scoped lang="less">
.content{
  padding-top: 4rem;
}
html, body {  
    overflow: auto;  
    height: 100%;  
}
.title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5f1985;
}
.name {
  width: 100%;
  text-align: center;
  color: #5f1985;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.infoList {
  display: flex;
  flex-direction: row;
}
.info {
  display: inline-block;
  width: 6rem;
}
.info2 {
  display: inline-block;
  width: 12rem;
}
</style>
