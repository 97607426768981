import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
import "./style/index.less";
import axios from "axios";

axios.defaults.baseURL = "http://47.241.73.160:80/shop";
axios.interceptors.request.use((config) => {
    config.headers.token = window.sessionStorage.getItem("token");
    return config;
});
Vue.prototype.$http = axios;
Vue.use(ViewUI);

Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
