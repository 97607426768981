<template>
  <div class="content">
    <mHeader></mHeader>
    <div class="title">POLO Products</div>
    <div class="title2">See our range of lubricants for your needs</div>
    <!-- 1 -->
    <div @click="goPage('Passenger Cars')">
      <img style="width: 100%;" src="../assets/passenger car.jpg" />
      <div class="title">Passenger Cars</div>
      <div class="more">Learn more ></div>
    </div>
    <!-- 2 -->
    <div @click="goPage('Motorcycles')">
      <img style="width: 100%;" src="../assets/motorcycle.jpg" />
      <div class="title">Motorcycles</div>
      <div class="more">Learn more ></div>
    </div>
    <!-- 3 -->
    <div @click="goPage('Light Duty Diesel & Pickup Trucks')">
      <img style="width: 100%;" src="../assets/pickup.jpg" />
      <div class="title">Light Duty Diesel & Pickup Trucks</div>
      <div class="more">Learn more ></div>
    </div>
    <!-- 4 -->
    <div @click="goPage('Heavy Duty Trucks & Buses')">
      <img style="width: 100%;" src="../assets/heavy duty.jpg" />
      <div class="title">Heavy Duty Trucks & Buses</div>
      <div class="more">Learn more ></div>
    </div>
    <!-- 5 -->
    <div @click="goPage('Transmission Fluids')">
      <img style="width: 100%;" src="../assets/transmission.jpg" />
      <div class="title">Transmission Fluids</div>
      <div class="more">Learn more ></div>
    </div>
    <!-- 6 -->
    <div @click="goPage('Industrial Oils')">
      <img style="width: 100%;" src="../assets/industrial.jpg" />
      <div class="title">Industrial Oils</div>
      <div class="more">Learn more ></div>
    </div>
    <mGoProducts></mGoProducts>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mHeader from "../components/mHeader";
import mFooter from "../components/mFooter";
import mGoProducts from "../components/mGoProducts";
export default {
  components: {
    mHeader,
    mFooter,
    mGoProducts
  },
  methods: {
    goPage(e) {
      localStorage.setItem("productCate", e);
      this.$router.push("/mProductsList");
    },
  },
};
</script>

<style scoped lang="less">
.content{
  padding-top: 4rem;
}
html, body {  
    overflow: auto;  
    height: 100%;  
}
.title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5f1985;
}
.title2 {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.more {
  width: 100%;
  text-align: center;
  color: #5f1985;
  font-size: 1rem;
  margin-bottom: 2rem;
}
</style>
