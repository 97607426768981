<template>
  <div style="margin: 120px">
    <div style="margin-bottom: 10px">
      账号：
      <Input v-model="name" placeholder="账号" style="width: 300px" />
    </div>
    <div style="margin-bottom: 10px">
      密码：
      <Input
        v-model="psd"
        type="password"
        placeholder="密码"
        style="width: 300px"
      />
    </div>
    <Button type="primary" @click="login">登录</Button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      psd: "",
    };
  },
  methods: {
    async login() {
      const { data: res } = await this.$http.post("/polo/user/login", {
        userName: this.name,
        passWord: this.psd,
      });
      if (res.code == 0) {
        window.sessionStorage.setItem("token", res.msg);
        this.$router.push("/control");
      }
    },
  },
};
</script>

<style>
</style>